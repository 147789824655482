<template>
  <div class="assessments__list--empty">
    <img src="~@/assets/images/empty-state/empty-state-avaliacoes.svg">

    <h3>Não há avaliações disponíveis</h3>
  </div>
</template>

<script>
export default {
  name: 'AssessmentsEmptyList',
}
</script>

<style lang="sass">

.assessments__list--empty
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  margin-top: $size-l
  overflow-x: hidden

  img
    height: 256px
    margin-bottom: $size-l

    +mq-xs
      width: 80%
      height: auto
      margin-bottom: $size-l

  p
    margin-top: $size-xxs
    margin-bottom: $size-m
    color: $color-ink-light

</style>
