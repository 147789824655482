<template>
  <Wrapper>
    <div class="assessments">
      <Box
        class="assessment-filter-box"
        elevation="0"
        padding="13px 30px 22px 0px"
      >
        <div>
          <s-select
            v-model="selectedYear"
            :label="$t('commons.year')"
            :loading="loading"
            :options="computedYears"
            track-by="yearExibition"
            @select="fetchAssessmentsByYear($event)"
          />
        </div>
        <div>
          <div class="assessment-filter-box-subgrid">
            <s-select
              v-model="selectedAssessmentType"
              :label="$t('commons.assessments')"
              :loading="loading"
              :options="assessmentTypes"
              @select="selectAssessmentType($event)"
            />
            <s-button
              v-if="$mq.includes('large')"
              :class="{ 'assessment-custom-button nohover': isLastSortingType }"
              :disabled="loading"
              nopadding
              variation="tertiary"
              @click="setListSortType('last')"
            >
              <p>{{ $t('bottomSheet.mostRecent') }}</p>
            </s-button>

            <s-button
              v-if="$mq.includes('large')"
              :class="{
                'assessment-custom-button nohover': isAlphabeticalSortingType,
              }"
              :disabled="loading"
              nopadding
              variation="tertiary"
              @click="setListSortType('alphabetical')"
            >
              <p>{{ $t('bottomSheet.alphabeticOrder') }}</p>
            </s-button>

            <s-button
              v-if="!$mq.includes('large')"
              slot="actions"
              class="icon-img"
              theme="float"
              @click="handleToggleDrawer('sort')"
            />
          </div>
        </div>
      </Box>
      <section
        v-if="loading || hasAssessments"
        class="assessments-types__list"
      >
        <div
          v-for="(filteredAssessment, index) in filteredAssessments"
          :id="index === 0 ? 'v-step-0' : ''"
          :key="filteredAssessment.id"
        >
          <AssessmentTypeCard
            :assessment-type="filteredAssessment"
            :loading="loading"
            @click.native="goToNextView(filteredAssessment)"
          />
        </div>
      </section>
      <AssessmentsEmptyList v-else />
      <bottomSheet
        v-if="isDrawerVisible"
        :type-helper="typeSort"
        @children-event="recieveEvent()"
        @close="handleToggleDrawer()"
      />
    </div>
    <TourAssessments
      v-if="!loading && isTourActive"
      :application="applications[0]"
      @next="isNextButtonPressed = true"
      @skip="skipTour()"
    />
  </Wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import sortBy from 'lodash/sortBy'

import { getCookie } from '@/utils/cookies'
import { getAuthSession } from '@/utils/auth'

import AssessmentTypeCard from '@/components/AssessmentTypeCard'
import bottomSheet from '@/components/global/bottomSheet'
import AssessmentsEmptyList from '@/components/AssessmentsEmptyList'
import urls from '@/utils/urls'

const TourAssessments = () => import('@/components/TourAssessments')

const SESSION_TOKEN = getCookie('session')

export default {
  name: 'Assessments',
  components: {
    TourAssessments,
    AssessmentTypeCard,
    bottomSheet,
    AssessmentsEmptyList,
  },
  data() {
    return {
      isNextButtonPressed: false,
      listSorting: 'alphabetical',
      selectedYear: null,
      previousSelectedYear: null,
      selectedAssessmentType: 'Todas',
      previousSelectedAssessmentType: 'Todas',
      isDrawerVisible: false,
      typeSort: null,
      years: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'assessments',
      'applications',
      'onboarding',
      'isLoading',
      'assessmentYears',
    ]),
    yearAssessments() {
      const sortingExamProperty = this.selectedAssessmentType
      let assessmentObject

      if (!sortingExamProperty || sortingExamProperty === 'Todas') {
        assessmentObject = this.assessments.filter(
          (assessment) => assessment.legacyAccess
            || assessment.exams,
        )
      } else {
        assessmentObject = this.assessments.filter(
          (assessment) => assessment.shortName === sortingExamProperty,
        )
      }

      const sortingProperty = this.listSorting === 'last'
        ? 'resultDate'
        : 'shortName'
      const sortDates = assessmentObject.sort(
        (a, b) => b[sortingProperty].localeCompare(a[sortingProperty]),
      )

      const sortNames = assessmentObject.sort(
        (a, b) => a[sortingProperty].localeCompare(b[sortingProperty]),
      )

      return sortingProperty === 'resultDate' ? sortDates.reverse() : sortNames
    },
    filteredAssessments() {
      return this.loading ? [{}] : this.yearAssessments
    },
    hasAssessments() {
      return this.filteredAssessments.length
    },
    computedYears() {
      this.assessmentYears.forEach((assessmentYear) => {
        const yearsValidation = !this.years.find((findYears) => {
          const isSameYear = findYears.year === assessmentYear.year
          const isSameGrade = findYears.grade === assessmentYear.grade

          return isSameYear && isSameGrade
        })

        if (yearsValidation) {
          const { year, grade, yearExibition = `${year} - ${grade}` } = assessmentYear
          this.years.push({
            year,
            grade,
            yearExibition,
          })
        }
      })

      return this.years.length
        ? this.years
        : [{ yearExibition: this.$t('commons.unavailable') }]
    },
    assessmentTypes() {
      const assessmentByTypes = [{ shortName: this.$t('commons.all') }]

      this.assessments.forEach((exam) => {
        const examValidation = !assessmentByTypes
          .find((examName) => examName.shortName === exam.shortName)

        if (examValidation) {
          const { shortName } = exam

          assessmentByTypes.push({ shortName })
        }
      })

      return assessmentByTypes.map(({ shortName }) => shortName)
    },
    isLastSortingType() {
      return this.listSorting === 'last'
    },
    isAlphabeticalSortingType() {
      return this.listSorting === 'alphabetical'
    },
    isTourActive() {
      return (
        this.onboarding.tourActive
        && !this.isLoading
        && this.assessments.length > 0
      )
    },
  },
  async created() {
    const { assessmentId } = this.$route.query
    if (assessmentId) {
      await this.goToStudentsResult(assessmentId)
    } else {
      this.loadAssessments()
    }
  },
  destroyed() {
    const isTourButtonPressed = this.isNextButtonPressed
      || this.onboarding.helpTourButtonPressed

    if (this.isTourActive && !isTourButtonPressed) {
      this.setOnboarding({
        ...this.onboarding,
        tourActive: false,
        helpActive: true,
        helpTourButtonPressed: false,
      })
      this.$tours.onboarding.stop()
    }
  },
  methods: {
    ...mapActions([
      'getAssessmentYears',
      'getApplications',
      'getApplication',
      'getAssessments',
      'setOnboarding',
      'showMainLoading',
      'hideMainLoading',
    ]),
    recieveEvent(payload) {
      this.setListSortType(payload.orderChoseen)
    },
    toggleLoading() {
      this.loading = !this.loading
    },
    handleToggleDrawer(typeSort) {
      this.typeSort = typeSort
      this.isDrawerVisible = !this.isDrawerVisible

      if (typeSort === 'sort') {
        const ANIMATION_DELAY = 100

        setTimeout(() => {
          this.isDrawerVisible = true
        }, ANIMATION_DELAY)
      }
    },
    async fetchAssessmentsByYear(selectedYear) {
      this.selectedYear = selectedYear || this.previousSelectedYear

      if (selectedYear) {
        this.previousSelectedYear = selectedYear
      }

      this.toggleLoading()
      try {
        const { year } = this.selectedYear
        const applications = await this.getApplications(year)
        await this.getAssessments(applications)
        this.selectedAssessmentType = this.$t('commons.all')
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.toggleLoading()
      }
    },
    selectAssessmentType(shortName) {
      this.selectedAssessmentType = shortName
        || this.previousSelectedAssessmentType

      if (shortName) {
        this.previousSelectedAssessmentType = shortName
      }
    },
    showErrorMessage(error) {
      this.$toasted.global.error({
        message: 'Ocorreu um erro ao carregar os simulados disponíveis.',
        errorCode: error.response ? error.response.status : '',
      })
    },
    setListSortType(order) {
      this.listSorting = order
    },
    async loadAssessments() {
      this.loading = true

      try {
        const years = await this.getAssessmentYears() || []
        const [currenntSelectedYear] = this.computedYears

        this.selectedYear = currenntSelectedYear
        if (years.length) {
          this.previousSelectedYear = this.selectedYear

          const { year } = this.selectedYear
          const applications = await this.getApplications(year)
          await this.getAssessments(applications)
          this.selectedAssessmentType = 'Todas'
        }
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.loading = false
      }
    },
    async goToStudentsResult(assessmentId) {
      this.showMainLoading()
      try {
        const applications = await this.getApplications()

        const application = applications.find((app) => {
          const appAssessmentId = parseInt(app.assessmentId, 10)
          const currentAssessmentId = parseInt(assessmentId, 10)

          return appAssessmentId === currentAssessmentId
        })

        if (application) {
          const { id, appointments } = application
          const applicationId = id
          const { examId } = appointments[0]
          const routeName = application.status === 'DONE' ? 'General' : 'Partial'
          this.$router.push({
            name: `examResult${routeName}`,
            params: {
              assessmentId,
              applicationId,
              examId,
            },
          })
        } else {
          await this.loadAssessments()
        }
      } catch (error) {
        this.$toasted.global.error({
          message: 'Ocorreu um erro ao carregar o catálog de avaliações.',
          errorCode: error.response ? error.response.status : '',
        })
      }
    },
    goToNextView(assessment) {
      const ENEM_TYPE_ID = 3

      const { applicationStatus, typeId } = assessment
      const application = this.applications.find((app) => assessment.id === app.assessmentId)
      const examId = sortBy(application.exams, ['name'])[0].id

      const hasDoneStatus = applicationStatus === 'DONE'
      const isEnemExam = typeId === ENEM_TYPE_ID

      const hasRankedWithResult = this.isRankedAssessmentWithResult({
        assessment,
        application,
      })

      if (hasDoneStatus && isEnemExam) {
        return this.goToFinalResult({
          assessment,
          examId,
        })
      }

      if (assessment.legacyAccess) {
        return this.goToLegacy(assessment)
      }

      if (hasRankedWithResult) {
        return this.handleRedirect({
          assessment,
          examId,
          application,
        })
      }

      if (isEnemExam) {
        return this.goToAssessment(assessment)
      }

      return this.goToStudos()
    },
    isRankedAssessmentWithResult({ assessment, application }) {
      const { applicationStatus } = application

      const hasRanking = assessment.typeSlug !== 'diagnostica'
      const hasResult = applicationStatus === 'PROCESSING_RESULT' || applicationStatus === 'DONE'

      return hasRanking && hasResult
    },
    handleFinalResultReleased({ assessment, examId, application }) {
      if (application.isFinalResultReleased) {
        return this.goToFinalResult({
          assessment,
          examId,
        })
      }

      return this.goToPartialResult({
        assessment,
        examId,
      })
    },
    handleRedirect({ assessment, examId, application }) {
      if (application.hasPartialResultReleased) {
        return this.handleFinalResultReleased({
          assessment,
          examId,
          application,
        })
      }

      return this.goToStudos()
    },
    goToFinalResult({ assessment, examId }) {
      const { id, applicationId } = assessment

      return this.$router.push({
        name: 'examResultGeneral',
        params: {
          assessmentId: id,
          applicationId,
          examId,
        },
      })
    },
    goToPartialResult({ assessment, examId }) {
      const { id, applicationId } = assessment

      return this.$router.push({
        name: 'examResultPartial',
        params: {
          assessmentId: id,
          applicationId,
          examId,
        },
      })
    },
    goToAssessment(assessment) {
      const { id, applicationId } = assessment

      this.$router.push({
        name: 'assessmentExams',
        params: {
          assessmentId: id,
          applicationId,
        },
      })
    },
    goToStudos() {
      const BASE_URL = `${urls.PORTAL_API}/redirects`
      const studosURL = 'student/central'
      const redirectURL = `${BASE_URL}/studos?token=${SESSION_TOKEN}&ssa=0&redirect=${studosURL}`

      return window.location.replace(redirectURL)
    },
    goToLegacy(assessment) {
      const legacyURL = assessment.legacyUrl.replace(/ /g, '%20')
      const token = getAuthSession()
      const URL = `${legacyURL}&token=${token}`

      return window.open(URL, '_blank')
    },
    skipTour() {
      this.setOnboarding({
        ...this.onboarding,
        tourActive: false,
        helpActive: false,
      })
    },
  },
}
</script>

<style lang="sass">
$color-filter-box: $color-ice

.assessments-container
  .header
    border-bottom: 1px solid $color-ink-lightest

  .assessments
    padding: 16px 0px 16px 0px
    box-sizing: border-box
    color: $color-ink

  .assessments-types__list
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 12px 18px
    margin-top: 24px

    +mq-m
      font-size: $font-size-heading-4
      grid-template-columns: 1fr 1fr 1fr

    +mq-s
      grid-template-columns: 1fr 1fr
      font-size: $font-size-heading-5

    +mq-xs
      width: 100%
      grid-template-columns: none

    .assessment-type-card
      +mq-s
        height: 164px
      +mq-xs
        flex-flow: row
        height: 76px
        justify-content: end
        align-items: baseline

      .assessment-type-card__title
        +mq-xs
          font-size: $font-size-heading-6

  .assessment-filter-box
    background-color: $color-filter-box
    margin-top: 18px
    grid-template-columns: auto 1fr
    grid-gap: 10px
    display: grid

    .sas-button
      box-shadow: none

    .sas-button__text
      p
        font-weight: $font-weight-bold

    .sas-select
      flex-direction: column

    +mq-m
      grid-template-rows: 1fr 1fr
      grid-template-columns: none

    .assessment-filter-box-subgrid
      display: grid
      align-items: flex-end
      margin-top: 2px
      grid-template-columns: auto auto auto
      grid-gap: $size-s
      background-color: $color-filter-box

      .icon-img
        background: url('~@/assets/images/import-export.svg') no-repeat
      +mq-m
        grid-template-columns: auto 35px
      +mq-s
        font-size: 14px
      +mq-xs
        font-size: 12px

      .assessment-custom-button
        background-color: $color-primary-lightest

    .sas-dropdown-menu
      width: 100%

    .sas-dropdown
      +mq-m
        width: 100%

    .sas-dropdown__button
      min-width: 264px
      +mq-s
        min-width: 100%

    .assessment-order-custom
      align-self: end
      text-align: center

    .s-header-subtitle
      color: $color-ink-light

</style>
