<template>
  <box
    :background="colorCard"
    class="assessment-type-card"
  >
    <skeleton-loader
      v-if="loading"
      class="skeleton-custom-block"
      :height="$tokens.size_l"
      :width="$tokens.size_l"
    />
    <img
      v-if="!getMQView && !loading"
      class="assessment-type-card__icon"
      :src="getIcon()"
    >
    <section v-if="loading">
      <skeleton-loader :height="$tokens.size_xl" />
      <skeleton-loader :height="$tokens.size_s" />
    </section>
    <section
      v-else
      class="card-grid"
    >
      <img
        v-if="getMQView"
        class="assessment-type-card__icon"
        :src="getIcon()"
      >
      <h4 class="assessment-type-card__title">
        {{ exibitionName }}
      </h4>
    </section>
  </box>
</template>

<script>
export default {
  name: 'AssessmentTypeCard',
  props: {
    assessmentType: {
      type: Object,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      colorCard: '#438DE4',
    }
  },
  computed: {
    exibitionName() {
      const { name, shortName, typeSlug } = this.assessmentType
      let { edition } = this.assessmentType
      edition = edition || name.substring(0, 1)
      edition = parseInt(edition, 10)

      if (!Number.isNaN(edition)) {
        edition = `${edition}°`
      } else {
        edition = ''
      }

      return typeSlug === 'olimpiadas' ? `${shortName}` : `${edition} ${shortName}`
    },
    getMQView() {
      const validationMQ = !!this.$mq.includes('x_small')

      return validationMQ
    },
  },
  methods: {
    goToNextView(assessment) {
      const application = this.assessmentType.find(
        (_application) => assessment.id === _application.assessmentId,
      )

      if (this.isRankedAssessmentWithResult({
        assessment,
        application,
      })) {
        const { examId } = application.appointments[0]

        if (this.isFinalResultReleased(application)) {
          this.goToFinalResult({
            assessment,
            examId,
          })
        } else {
          this.goToPartialResult({
            assessment,
            examId,
          })
        }
      } else {
        this.goToAssessment(assessment)
      }
    },
    getIcon() {
      const images = require.context('@/assets/', false, /\.svg$/)

      return images(`./${this.assessmentType.imageUrl}`)
    },
  },
}
</script>

<style lang="sass">
$card-height: 240px

.assessment-type-card
  display: flex
  flex-direction: column
  justify-content: space-between
  height: $card-height
  color: $color-white
  background-image: url("~@/assets/card-texture.svg") !important

  +mq-m
    margin-bottom: 10px

  +mq-s
    padding: 20px

  .card-grid
    +mq-s
      grid-template-columns: auto auto
      display: grid
      grid-gap: 10px !important

  .skeleton-custom-block
    +mq-s
      position: absolute

  &:hover
    cursor: pointer
    box-shadow: $shadow-l $color-ink-lighter

  &__icon
    width: $size-l
    height: $size-l

  &__title
    align-self: center

    +mq-l
      margin-top: 128px 0px 0px 0px

    +mq-s
      text-align: start

  .sas-skeleton-loader
    background: $color-primary

  .sas-skeleton-loader ~ .sas-skeleton-loader
    margin-top: $size-xs

    +mq-s
      display: inline
      align-self: center

</style>
