<template>
  <drawer
    background="white"
    :class="classDrawer"
    :position="positionDrawer"
    :size="sizeDrawer"
    @close="$emit('close', prepareTypeHelperTip)"
  >
    <div class="drawer-header">
      <wrapper class="drawer-header__wrapper">
        <div class="drawer-header__title">
          <p v-if="typeHelper === 'sort'">
            {{ $t('bottomSheet.ordering') }}
          </p>
          <h4 v-else>
            {{ $t('bottomSheet.chooseOption') }}
          </h4>
        </div>
        <s-button
          icon="x"
          variation="tertiary"
          @click="$emit('close', prepareTypeHelperTip)"
        />
      </wrapper>
    </div>
    <wrapper>
      <div
        v-if="typeHelper === 'sort'"
        class="drawer-content"
      >
        <div class="subgrid">
          <input
            id="last"
            v-model="picked"
            class="checkmark"
            type="radio"
            value="last"
          >
          <label for="last">{{ $t('bottomSheet.mostRecent') }}</label>
        </div>
        <div class="subgrid">
          <input
            id="alphabetical"
            v-model="picked"
            class="checkmark"
            type="radio"
            value="alphabetical"
          >
          <label for="alphabetical">
            {{ $t('bottomSheet.alphabeticOrder') }}
          </label>
        </div>
      </div>
    </wrapper>
    <div class="drawer-footer">
      <wrapper class="drawer-footer__wrapper">
        <s-button
          class="drawer-custom-button"
          theme="primary"
          @click="sendEvent()"
        >
          <p>{{ $t('bottomSheet.components.order') }}</p>
        </s-button>
      </wrapper>
    </div>
  </drawer>
</template>

<script>
export default {
  name: 'QuestionHelper',
  props: {
    typeHelper: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      picked: '',
    }
  },
  computed: {
    isMqSmallOrTypeHelperTip() {
      return this.$mq === 'small' || 'medium' || 'x-small'
    },
    positionDrawer() {
      return 'bottom'
    },
    sizeDrawer() {
      const sizeXLarge = this.$mq === 'x_large' ? '490px' : '30%'

      return this.isMqSmallOrTypeHelperTip
        ? null
        : sizeXLarge
    },
    classDrawer() {
      return this.typeHelper !== null ? `--${this.typeHelper}` : ''
    },
    prepareTypeHelperTip() {
      return this.typeHelper === 'tip' ? 'tip' : null
    },
  },
  methods: {
    sendEvent() {
      this.$emit('children-event', { orderChoseen: this.picked })
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass" scoped>
$font-text-color: #1E2124
$border-color-custom: rgba(0, 0, 0, 0.25)

::v-deep .drawer
  transition: transform $speed-fast ease-in-out !important
  height: initial
  max-height: 273px
  font-color: $font-text-color
  font-weight: $font-weight-semi-bold

  &.--position-bottom
    border-radius: 16px 16px 0 0

  &-header
    padding: 16px 0
    box-shadow: $shadow-s $border-color-custom
    background: $color-white
    position: sticky
    top: 0
    z-index: 100

    &__wrapper
      +flex-space-between

    &__title
      +flex-center-start

      img
        margin-right: $size-s

      p
        font-style: normal
        font-weight: 500
        font-size: $font-size-heading-5
        line-height: 28px

  &-footer
    padding: $size-s 0
    box-shadow: 0px 0px 7px $border-color-custom
    background: $color-white
    position: sticky
    top: 0
    z-index: 100

    .drawer-custom-button
        width: 100%

  &-content
    padding: $size-m 0

    .subgrid
      margin-top: 10px
      margin-bottom: $size-s
      grid-template-columns: 25px 1fr
      display: grid
      grid-gap: 2px

      label
        font-size: $font-size-m
        line-height: $size-m
        font-color: $color-ink

      .checkmark
        width: 20px
        height: 20px

    & > div
      margin-bottom: 28px

    +mq-xl
      padding-left: $size-s
      padding-right: $size-l

    &__title
      margin-bottom: $size-s

      +mq-xs
        +flex-center
        flex-flow: column

      h5
        margin-bottom: $size-s
</style>
